import { Injectable } from '@angular/core';
import { FlowDesign } from 'src/app/features/chat-bot/types/chat-flow.interface';

@Injectable({
  providedIn: 'root'
})
export class BotStylingService {
  applyStyles(
    selector: string,
    styles: Record<string, string>,
    shadowRoot: ShadowRoot | null = null
  ): void {
    const styleContent = this.generateStyleContent(selector, styles);
    this.appendStyleToHead(styleContent, shadowRoot);
  }

  private generateStyleContent(
    selector: string,
    styles: Record<string, string>
  ): string {
    let styleContent = `${selector} { `;
    Object.keys(styles).forEach((styleName) => {
      styleContent += `${styleName}: ${styles[styleName]} !important; `;
    });
    styleContent += `}`;
    return styleContent;
  }

  private appendStyleToHead(
    styleContent: string,
    shadowRoot: ShadowRoot | null
  ): void {
    const styleTag = document.createElement('style');
    styleTag.type = 'text/css';
    styleTag.innerHTML = styleContent;

    if (shadowRoot) {
      shadowRoot.appendChild(styleTag);
    } else {
      document.getElementsByTagName('head')[0].appendChild(styleTag);
    }
  }

  applyDynamicStyling(
    flowDesign: FlowDesign,
    shadowRoot: ShadowRoot | null = null
  ): void {
    if (flowDesign) {
      this.applyStyles('html', {
        'font-family': `${flowDesign.typography.font_family}, Montserrat, Arial, sans-serif`,
        '--mdc-typography-font-family': `${flowDesign.typography.font_family}, Montserrat, Arial, sans-serif`,
        '--mdc-typography-button-font-family': `${flowDesign.typography.font_family}, Montserrat, Arial, sans-serif`,
        '--mat-menu-item-label-text-font': `${flowDesign.typography.font_family}, Montserrat, Arial, sans-serif`
      });

      this.applyStyles(
        '.app-container-chatbot',
        {
          background: flowDesign.background_color
        },
        shadowRoot
      );
      this.applyStyles(
        '.text-help',
        {
          color: flowDesign.help_text_color
        },
        shadowRoot
      );
      this.applyStyles(
        '.mdc-checkbox',
        {
          color: flowDesign.typography.bot_text_color
        },
        shadowRoot
      );
      this.applyStyles(
        'button.user-option-button',
        {
          'border-radius': flowDesign.button.border_radius + 'px'
        },
        shadowRoot
      );
      this.applyStyles(
        '.agent-message-box',
        {
          color: flowDesign.typography.bot_text_color
        },
        shadowRoot
      );
      this.applyStyles(
        '.feedback-checkbox-option label',
        {
          color: flowDesign.typography.bot_text_color
        },
        shadowRoot
      );
      this.applyStyles(
        '.user-message-box',
        {
          color: flowDesign.button.text_color,
          'background-color': flowDesign.button.background_color
        },
        shadowRoot
      );

      this.applyStyles(
        '.mat-mdc-unelevated-button',
        {
          '--mdc-filled-button-container-color':
            flowDesign.button.background_color,
          '--mdc-filled-button-label-text-color': flowDesign.button.text_color
        },
        shadowRoot
      );

      this.applyStyles(
        '.user-option-button.mat-mdc-unelevated-button.outline:not(:disabled)',
        {
          color: flowDesign.button.background_color,
          'background-color': flowDesign.button.text_color,
          border: `1px solid ${flowDesign.button.background_color}`
        },
        shadowRoot
      );

      this.applyStyles('html', {
        // '--mdc-outlined-text-field-hover-outline-color':
        //   flowDesign.button.background_color,
        // '--mdc-outlined-text-field-focus-outline-color':
        //   flowDesign.button.background_color,

        '--mat-autocomplete-background-color': '#fff',
        '--mat-select-panel-background-color': '#fff',
        '--mdc-outlined-text-field-focus-label-text-color':
          flowDesign.button.background_color,
        '--mdc-filled-text-field-focus-label-text-color':
          flowDesign.button.background_color,
        '--mdc-outlined-text-field-input-text-color':
          flowDesign.typography.bot_text_color,
        '--mdc-outlined-text-field-input-text-placeholder-color':
          'rgba(0, 0, 0, 0.38)'
      });
      const botTextColorWithOpacity =
        flowDesign.typography.bot_text_color + '33';
      this.applyStyles(
        ':host',
        {
          '--dot-color': flowDesign.typography.bot_text_color,
          '--dot-color-20': botTextColorWithOpacity
        },
        shadowRoot
      );
    }
  }
}
