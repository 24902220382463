<ui-chat-bot-sa-element
  *ngIf="chatFlow && funnelLanguage"
  [flowDesign]="chatFlow.design!"
  [chatFlow]="chatFlow"
  [testMode]="false"
  [funnelLanguage]="funnelLanguage"
></ui-chat-bot-sa-element>

<ng-container *ngIf="errorFunnelStatus">
  <div class="error-container-wrapper">
    <div class="error-box">
      <mat-icon
        [svgIcon]="
          errorFunnelStatus === 'funnel_not_published'
            ? 'construction-emoji'
            : 'fog-emoji'
        "
        style="width: 120px; height: 120px"
      ></mat-icon>

      <div class="h1-error-title">
        {{
          errorFunnelStatus === 'funnel_not_published'
            ? 'Questionnaire not yet published'
            : 'This questionnaire does not exist'
        }}
      </div>
      <div *ngIf="errorFunnelStatus === 'funnel_not_published'">
        Please come back later. We appreciate your patience.
      </div>
    </div>
  </div>
  <powered-by-hypt *ngIf="!chatFlow?.design?.white_labeling"></powered-by-hypt>
</ng-container>
