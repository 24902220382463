<mat-form-field appearance="outline" class="full-width user-input-form">
  <input
    type="text"
    matInput
    placeholder="Search..."
    [(ngModel)]="searchText"
    [matAutocomplete]="auto"
    (focus)="filterEmployees()"
    (input)="filterEmployees()"
  />
  <mat-autocomplete
    (optionSelected)="onEmployeeChange($event)"
    #auto="matAutocomplete"
    [displayWith]="displayEmployeeName"
  >
    <mat-option *ngFor="let employee of filteredEmployees" [value]="employee">
      {{ employee.employee_name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
