import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageConfigObject } from 'src/app/features/chat-bot/types/chat-flow.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {
  @Input() languageObject: LanguageConfigObject | undefined;
  @Output() currentLanguageChanged = new EventEmitter<string>();
  @Input() selectedLanguage: string | undefined;

  constructor(private translateService: TranslateService) {}
  ngOnInit(): void {
    if (this.languageObject && this.selectedLanguage) {
      this.initLanguage();
    }
  }

  private initLanguage() {
    if (this.selectedLanguage) {
      this.translateService.setDefaultLang(this.selectedLanguage);
      this.translateService.use(this.selectedLanguage);
      this.currentLanguageChanged.emit(this.selectedLanguage);
    }
  }

  onSelectLanguage(selectedLanguage: string) {
    this.selectedLanguage = selectedLanguage;
    this.currentLanguageChanged.emit(selectedLanguage);
  }
}
