import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoweredByHyptComponent } from '../features/chat-bot/components/powered-by-hypt/powered-by-hypt.component';
import { ChatStepComponent } from '../features/chat-bot/components/chat-step/chat-step.component';
import { LoadingDotsComponent } from '../features/chat-bot/components/loading-dots/loading-dots.component';
import { AgentMessageFieldComponent } from '../features/chat-bot/components/agent-message-field/agent-message-field.component';
import { UserInputAreaComponent } from '../features/chat-bot/components/user-input-area/user-input-area.component';
import { UserMessageFieldComponent } from '../features/chat-bot/components/user-message-field/user-message-field.component';
import { UserOptionsComponent } from '../features/chat-bot/components/user-options/user-options.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatRippleModule } from '@angular/material/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IconRegistryService } from './services/icon-registry.service';
import { HttpClientModule } from '@angular/common/http';
import { UserInputContactFieldsComponent } from '../features/chat-bot/components/user-input-contact-fields/user-input-contact-fields.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { UserEmployeeSelectionComponent } from '../features/chat-bot/components/user-input-employee-selection/user-employee-selection.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
// import { HttpLoaderFactory } from './utils/translate-loaders';

@NgModule({
  declarations: [
    PoweredByHyptComponent,
    ChatStepComponent,
    LoadingDotsComponent,
    UserMessageFieldComponent,
    UserInputAreaComponent,
    UserOptionsComponent,
    UserEmployeeSelectionComponent,
    AgentMessageFieldComponent,
    UserInputContactFieldsComponent,
    LanguageSwitcherComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    MatMenuModule,
    // new
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    MatCheckboxModule,
    MatAutocompleteModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    // }),

    TranslateModule
  ],
  exports: [
    PoweredByHyptComponent,
    ChatStepComponent,
    LoadingDotsComponent,
    UserMessageFieldComponent,
    UserInputAreaComponent,
    UserOptionsComponent,
    UserEmployeeSelectionComponent,
    AgentMessageFieldComponent,
    UserInputContactFieldsComponent,
    LanguageSwitcherComponent,

    // modules
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    MatMenuModule,
    HttpClientModule,
    MatCheckboxModule,
    TranslateModule
  ],
  providers: [IconRegistryService]
})
export class SharedModule {}
