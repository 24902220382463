import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepService {
  private isUserOptionsButtonDisabledSubject = new BehaviorSubject<boolean>(
    false
  );
  public isUserOptionsButtonDisabled$ =
    this.isUserOptionsButtonDisabledSubject.asObservable();

  setIsUserOptionsButtonDisabled(isDisabled: boolean): void {
    this.isUserOptionsButtonDisabledSubject.next(isDisabled);
  }
}
