import { Component, Input, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations';
import { CustomVariablesService } from '../ui-chat-bot-sa/custom-variables.service';
import { CustomVariable } from '../../types/chat-flow.interface';

@Component({
  selector: 'agent-message-field',
  templateUrl: './agent-message-field.component.html',
  styleUrls: ['./agent-message-field.component.scss'],

  animations: [
    trigger('rotateInDownLeft', [
      state(
        'void',
        style({
          transform: 'rotate3d(0, 0, 1, 5deg) scale(.92)',

          transformOrigin: 'left bottom',
          opacity: 0
        })
      ),
      transition(':enter', [
        animate(
          '200ms ease-out',
          keyframes([
            style({
              transform: 'rotate3d(0, 0, 1, 3deg) scale(.95)',
              opacity: 0,
              offset: 0.1
            }), // 30% into the animation timeline
            style({
              transform: 'rotate3d(0, 0, 1, 2deg) scale(.97)',
              opacity: 0.3,
              offset: 0.5
            }), // 50% into the animation timeline
            style({ transform: 'rotate(0) scale(1)', opacity: 1, offset: 1 }) // 100% completion
          ])
        )
      ])
    ])
  ]
})
export class AgentMessageFieldComponent implements OnInit {
  @Input() message: string | undefined;

  dynamicVariables: CustomVariable | null = null;
  opacityTransition: boolean = false;
  constructor(private customVariablesService: CustomVariablesService) {}

  ngOnInit(): void {
    this.customVariablesService.customVariables$.subscribe((variables) => {
      this.dynamicVariables = variables;
      if (this.message) {
        this.message = this.updateMessageContent(this.message);
      }
    });

    setTimeout(() => {
      this.opacityTransition = true;
    }, 0);
  }

  updateMessageContent(message: string): string {
    if (this.dynamicVariables) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(message, 'text/html');
      const spans = doc.querySelectorAll('span.param');

      spans.forEach((span) => {
        const variableName = span.textContent?.trim();
        if (
          variableName &&
          this.dynamicVariables?.hasOwnProperty(variableName)
        ) {
          span.replaceWith(this.dynamicVariables[variableName]);
        } else {
          span.remove();
        }
      });

      return doc.body.innerHTML;
    }
    return message;
  }
}
