import {
  ApiConfig,
  ContactFormStep,
  EmployeeSelectionStep,
  ExternalLandingPageStep,
  FeedbackStep,
  GoodbyeStep,
  GoogleReviewStep,
  GoogleReviewStepAction,
  MessageStep,
  RatingStep,
  WordOfMouthChannelsStep,
  WordOfMouthStep
} from './steps';

export type Step =
  | ContactFormStep
  | FeedbackStep
  | RatingStep
  | WordOfMouthStep
  | WordOfMouthChannelsStep
  | GoogleReviewStep
  | GoodbyeStep
  | MessageStep
  | ExternalLandingPageStep
  | EmployeeSelectionStep;

export enum StepType {
  MESSAGE = 'MESSAGE',
  QUESTION = 'QUESTION',
  RATING = 'RATING',
  FEEDBACK = 'FEEDBACK',
  RECOMMENDATION = 'RECOMMENDATION',
  WORD_OF_MOUTH = 'WORD_OF_MOUTH',
  WORD_OF_MOUTH_CHANNELS = 'WORD_OF_MOUTH_CHANNELS',
  GOOGLE_REVIEW = 'GOOGLE_REVIEW',
  CONTACT_FORM = 'CONTACT_FORM',
  EMPLOYEE_SELECTION = 'EMPLOYEE_SELECTION',
  EXTERNAL_LANDING_PAGE = 'EXTERNAL_LANDING_PAGE',
  NPS = 'NPS',
  GOODBYE = 'GOODBYE',
  JUMP = 'JUMP',
  SKIPPABLE = 'SKIPPABLE',
  LANGUAGE_CONFIG = 'LANGUAGE_CONFIG' // not an actual step type
}

export interface ApiStep<T extends StepType> extends BaseStep {
  type: StepType; // You can make this more specific or use enums
  api: ApiConfig<T>;
}

export interface BaseStep {
  step: number;
  type: StepType;
  messages: StepMessage[];
  input_content: StepContent[];
  actions: StepAction[];
}

export type StepAction = BaseStepAction | GoogleReviewStepAction;

export interface BaseStepAction {
  text: { [lang: string]: string };
  icon: string;
  width?: number;
  button?: { [key: string]: string };
  is_visible?: boolean;
  action: {
    jump: number;
    link?: { [key: string]: string };
    redirect?: { [key: string]: string };
  };
  condition_value: string | number;
}

export interface StepContent {
  type: string;
  control: string;
  text: string;
}

export interface StepMessage {
  message: { [lang: string]: string };
}
