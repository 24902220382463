import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EmployeeSelectionStepData } from '../../types/steps';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { StepService } from 'src/app/shared/services/step.service';

@Component({
  selector: 'user-employee-selection',
  templateUrl: './user-employee-selection.component.html',
  styleUrls: ['./user-employee-selection.component.scss']
})
export class UserEmployeeSelectionComponent {
  @Input() employees?: EmployeeSelectionStepData[];
  filteredEmployees?: EmployeeSelectionStepData[];
  @Output() onChangeEmployee = new EventEmitter<EmployeeSelectionStepData>();
  searchText: string = '';

  constructor(private stepService: StepService) {}

  onEmployeeChange(event: MatAutocompleteSelectedEvent) {
    this.onChangeEmployee.emit(event.option.value);
    this.stepService.setIsUserOptionsButtonDisabled(true);
  }
  filterEmployees(): void {
    const filterValue =
      typeof this.searchText === 'string' ? this.searchText.toLowerCase() : '';
    this.filteredEmployees = this.employees?.filter((employee) =>
      employee.employee_name.toLowerCase().includes(filterValue)
    );
  }
  displayEmployeeName(employee?: EmployeeSelectionStepData): string {
    return employee ? employee.employee_name : '';
  }
}
