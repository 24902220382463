import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ChatFlow,
  CustomVariable,
  FlowDesign
} from '../../types/chat-flow.interface';
import { ScrollService } from '../../scroll.service';
import { BotStylingService } from 'src/app/shared/services/bot-styling.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { Subject, takeUntil } from 'rxjs';
import { ChatBotFlowSaService } from './chat-bot-flow-sa.service';
import { IconRegistryService } from 'src/app/shared/services/icon-registry.service';
import { TranslateService } from '@ngx-translate/core';
import { Step } from '../../types/steps.type';
import { CustomVariablesService } from './custom-variables.service';

@Component({
  selector: 'ui-chat-bot-sa',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './ui-chat-bot-sa.component.html',
  styleUrls: ['./ui-chat-bot-sa.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class UiChatBotSaComponent implements OnInit {
  private destroy$ = new Subject<void>();
  @Input() chatFlow: ChatFlow | undefined;
  @Input() flowDesign: FlowDesign | undefined;
  @Input() testMode: boolean | undefined;
  @Input() funnelLanguage: string | undefined;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  steps: Step[] | null = null;
  private shadowRoot!: ShadowRoot;
  dynamicVariables: CustomVariable | null = null;

  constructor(
    private scrollService: ScrollService,
    private stylingService: BotStylingService,
    private chatBotFlowSaService: ChatBotFlowSaService,
    private iconRegistry: IconRegistryService,
    private translateService: TranslateService,
    private elRef: ElementRef,
    private customVariablesService: CustomVariablesService
  ) {
    this.iconRegistry.initialize();
  }

  ngOnInit(): void {
    // Apply initial styles
    this.shadowRoot = this.elRef.nativeElement.shadowRoot;
    // this.setCSSVariables(); // Update CSS variables
    if (this.flowDesign) {
      this.stylingService.applyDynamicStyling(this.flowDesign, this.shadowRoot);
    }

    this.customVariablesService.customVariables$.subscribe((variables) => {
      this.dynamicVariables = variables;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log('chatFlow', this.chatFlow);

    this.translateService.onLangChange.subscribe((event) => {
      this.funnelLanguage = event.lang; // Access the new language
      this.updateAllMessages(event.lang);
    });

    if (changes['flowDesign'] && this.flowDesign) {
      this.stylingService.applyDynamicStyling(this.flowDesign, this.shadowRoot);
    }

    if (changes['chatFlow'] && this.chatFlow && this.flowDesign) {
      this.chatBotFlowSaService.setFlowConfig(this.chatFlow);
      this.stylingService.applyDynamicStyling(this.flowDesign, this.shadowRoot);
      this.chatBotFlowSaService.userSteps$
        .pipe(takeUntil(this.destroy$))
        .subscribe((steps) => {
          this.steps = steps;
        });

      this.scrollService.triggerScrollToBottom$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          setTimeout(() => {
            this.scrollToBottom();
          }, 100);
        });
    }
  }

  updateAllMessages(newLang: string): void {
    if (this.steps) {
      this.steps.forEach((step) => {
        step.messages.forEach((message) => {
          if (message.message[newLang]) {
            message.message[newLang] = this.updateMessageContent(
              message.message[newLang]
            );
          }
        });
      });
    }
  }

  updateMessageContent(message: string): string {
    if (this.dynamicVariables) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(message, 'text/html');
      const spans = doc.querySelectorAll('span.param');
      spans.forEach((span) => {
        const variableName = span.textContent?.trim();
        if (
          variableName &&
          this.dynamicVariables?.hasOwnProperty(variableName)
        ) {
          span.replaceWith(this.dynamicVariables[variableName]);
        } else {
          span.remove();
        }
      });

      return doc.body.innerHTML;
    }
    return message;
  }

  prefetchAvatarImage(url: string): void {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      if (this.flowDesign) {
        this.flowDesign.avatars.selected = url;
      }
    };
  }
  scrollToBottom() {
    const container = this.scrollContainer.nativeElement;
    container.style.scrollBehavior = 'smooth';
    container.scrollTop = container.scrollHeight;
  }

  onLanguageChanged(languageToSetTo: string): void {
    // let isInitialLoad = true;
    if (this.chatFlow && !this.testMode) {
      this.chatFlow.language.api.data.language = languageToSetTo;
      this.chatBotFlowSaService
        .updateFeedbackApi(this.chatFlow.language.api)
        .subscribe((response) => {
          // console.log('response Lang update', response);
        });
    }

    this.funnelLanguage = languageToSetTo;
    this.translateService.use(languageToSetTo);
  }
}
