import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ScrollService } from '../../scroll.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { MESSAGE_DELAY } from 'src/app/shared/constants/global.constants';
import { ChatBotFlowSaService } from '../ui-chat-bot-sa/chat-bot-flow-sa.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import {
  FlowDesign,
  EmployeeSelectionStepData,
  CustomVariable
} from '../../types/steps';
import {
  Step,
  StepMessage,
  StepType,
  StepAction
} from '../../types/steps.type';
import { CustomVariablesService } from '../ui-chat-bot-sa/custom-variables.service';

@Component({
  selector: 'chat-step',
  templateUrl: './chat-step.component.html',
  styleUrls: ['./chat-step.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 }))
      ])
    ]),
    trigger('scale', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate(
          `${MESSAGE_DELAY - 200}ms ease-in`,
          style({
            opacity: 1
          })
        )
      ])
    ])
  ]
})
export class ChatStepComponent implements OnInit {
  private destroy$ = new Subject<void>();
  @ViewChild('agentWrapper') agentIcon!: ElementRef;
  @ViewChild('messagesContainer') messagesContainer!: ElementRef;
  offsetY: number = 0;
  previousHeight: number = 0;
  @Output()
  scrollToBottom: EventEmitter<void> = new EventEmitter<void>();
  @Output() optionSelectedEvent = new EventEmitter<void>();
  @Input() step: Step | null = null;
  @Input() flowDesign: FlowDesign | null = null;
  @Input() currentLang!: string;
  @Input() testMode?: boolean;
  fadeInState: string = 'hidden';
  userMessage: string = '';
  displayedMessages: StepMessage[] = [];
  allAgentMessagesDisplayed: boolean = false;
  inputValues: Record<string, string> = {};
  feedback: string = '';
  stepType = StepType;
  dynamicVariables: CustomVariable | null = null;

  constructor(
    private chatBotFlowSaService: ChatBotFlowSaService,
    private scrollService: ScrollService,
    private cdr: ChangeDetectorRef,
    private customVariablesService: CustomVariablesService
  ) {}

  ngOnInit(): void {
    // console.log('chat-step', this.step);

    // this can be moved in to component that handles logic of checkboxes
    if (this.step && this.step.type === StepType.FEEDBACK) {
      this.step.additional_config.checkbox_options?.forEach((option) => {
        option.checked = false; // Initialize all as unchecked
      });
    }
    if (this.step && this.step.type === StepType.WORD_OF_MOUTH_CHANNELS) {
      this.customVariablesService.customVariables$.subscribe((variables) => {
        this.dynamicVariables = variables;
        if (this.step) {
          this.step.actions.forEach((action) => {
            if (
              action.action.redirect &&
              typeof action.action.redirect === 'object'
            ) {
              Object.keys(action.action.redirect).forEach((langKey) => {
                const redirect = action.action.redirect as {
                  [key: string]: string;
                };
                const updatedUrl = this.processUrl(redirect[langKey]);
                redirect[langKey] = updatedUrl; // Assign the new URL to redirect[langKey]
              });
            }
          });
        }
      });
    }
  }

  updateMessageContent(message: string): string {
    if (this.dynamicVariables) {
      // Decode the URL
      const decodedMessage = decodeURIComponent(message);

      // Preprocess the message to handle adjacent double underscores
      const preprocessedMessage = decodedMessage.replace(/____/g, '__ __');

      // Replace the parameters in the preprocessed message
      const updatedMessage = preprocessedMessage.replace(
        /__(\w+)__/g,
        (match, p1) => {
          const variableName = p1.trim();
          if (
            variableName &&
            this.dynamicVariables &&
            this.dynamicVariables.hasOwnProperty(variableName)
          ) {
            // Return the replacement value without encoding
            return this.dynamicVariables[variableName];
          }

          return ''; // Remove the variable if it is not found
        }
      );

      // Return the updated message
      return updatedMessage;
    }
    return message;
  }
  encodeURIComponentWithSpaces(str: string): string {
    return encodeURIComponent(str).replace(/%20/g, '%20');
  }

  processUrl(url: string): string {
    const [baseUrl, queryParams] = url.split('?');
    if (!queryParams) {
      return url;
    }

    const params = new URLSearchParams(queryParams);
    const textParam = params.get('text');
    const bodyParam = params.get('body');
    const subjectParam = params.get('subject');

    let updatedParams = '';

    if (textParam) {
      const updatedText = this.updateMessageContent(textParam);
      updatedParams += `text=${this.encodeURIComponentWithSpaces(updatedText)}`;
    }

    if (bodyParam) {
      if (updatedParams) updatedParams += '&';
      const updatedBody = this.updateMessageContent(bodyParam);
      updatedParams += `body=${this.encodeURIComponentWithSpaces(updatedBody)}`;
    }

    if (subjectParam) {
      if (updatedParams) updatedParams += '&';
      const updatedSubject = this.updateMessageContent(subjectParam);
      updatedParams += `subject=${this.encodeURIComponentWithSpaces(updatedSubject)}`;
    }

    return `${baseUrl}?${updatedParams}`;
  }
  ngAfterViewInit(): void {
    if (
      this.messagesContainer &&
      this.messagesContainer.nativeElement &&
      !this.allAgentMessagesDisplayed
    ) {
      this.previousHeight = this.messagesContainer.nativeElement.offsetHeight;
      this.showMessagesWithDelay();
    }
  }

  showMessagesWithDelay() {
    let index = 0;
    const addNextMessage = () => {
      if (this.step && index < this.step.messages.length) {
        const message = this.step.messages[index];
        if (message && message.message) {
          this.displayedMessages.push(message); // if you want to access the first object
        }
        index++;
        // this trigger transition immediately even if message hight is larger then 2 lines
        setTimeout(() => {
          this.updateIconPosition();
        });
        setTimeout(() => {
          this.scrollService.scrollToBottom();
        }, 100);
        setTimeout(() => {
          // After the last message
          if (this.step && index >= this.step.messages.length) {
            setTimeout(() => {
              this.allAgentMessagesDisplayed = true;
            }, MESSAGE_DELAY);
          } else {
            // If there are still messages left, set a new timeout for the next one
            setTimeout(addNextMessage, MESSAGE_DELAY);
          }
          this.cdr.detectChanges();
        }, MESSAGE_DELAY);
      }
    };

    addNextMessage();
  }

  // triggers on any button click from user
  setNextStep(option: StepAction) {
    // console.log('testMode', this.testMode);
    // Triggers API call if step has API config
    if (this.step) {
      /**
       * Here we check if and skip logic if test mode is on, we continue logic if step type
       * is employee selection to do not break funnel in test mode and get correct employee data from response
       */
      if (this.step.api && !this.testMode) {
        if (
          this.step.api?.data &&
          'condition_value' in this.step.api.data &&
          this.step.type !== StepType.EMPLOYEE_SELECTION
        ) {
          this.step.api.data.condition_value = option.condition_value;
        }
        if (
          this.step.type === StepType.EXTERNAL_LANDING_PAGE &&
          typeof option.action.redirect === 'object' &&
          option.action.redirect !== null
        ) {
          // Set feedback text value to the API data
          this.step.api.data.landing_page_url =
            option.action.redirect[this.currentLang];
        }
        // set API conditions here`
        this.chatBotFlowSaService
          .updateFeedbackApi(this.step.api)
          .subscribe((response) => {
            // console.log('response', response);
            if (
              response.variables &&
              this.step?.type === this.stepType.EMPLOYEE_SELECTION
            ) {
              this.customVariablesService.updateVariables(response.variables);
            }
          });
      }
      // Opens a new tab if redirect is present
      if (option.action.redirect) {
        let optionLink: string;
        if (this.step.type === StepType.GOOGLE_REVIEW) {
          // Directly assign the redirect link for GOOGLE_REVIEW type
          optionLink = option.action.redirect as string;
        } else if (
          typeof option.action.redirect === 'object' &&
          option.action.redirect !== null
        ) {
          // Use the language key selector for other types
          optionLink = option.action.redirect[this.currentLang];
        } else {
          // Directly assign the redirect link if it's a string
          optionLink = option.action.redirect;
        }

        window.open(optionLink, '_blank');
      }

      const delay =
        this.step.type === StepType.MESSAGE
          ? MESSAGE_DELAY
          : MESSAGE_DELAY + 400;

      setTimeout(() => {
        this.chatBotFlowSaService.addStepToSortedStepsById(option.action.jump);
        // How long it will take after user selects option to show next step
      }, delay);

      this.userMessage = option.text[this.currentLang];
    }
  }
  onFeedbackTextArea(value: string) {
    // Set feedback text value to the API data
    if (this.step?.type === StepType.FEEDBACK) {
      this.step.api.data.feedback.text = value;
    }
  }
  trackByFn(index: number, item: any): any {
    return item?.id || index;
  }

  updateIconPosition() {
    setTimeout(() => {
      const currentHeight = this.messagesContainer.nativeElement.offsetHeight;
      const heightDifference = currentHeight - this.previousHeight;
      this.offsetY += heightDifference;
      this.previousHeight = currentHeight;
    });
  }
  onContactFieldValueChange(event: { key: string; value: string }): void {
    if (this.step && this.step.type === StepType.CONTACT_FORM) {
      this.inputValues[event.key] = event.value;
      this.step.api.data = { ...this.step.api.data, ...this.inputValues };
    }
  }
  onCheckboxChange(
    event: MatCheckboxChange,
    option: { checked?: boolean; [key: string]: any }
  ): void {
    if (this.step?.type === StepType.FEEDBACK) {
      const isChecked = event.checked;
      // Update your option object or handle the change as needed
      option.checked = isChecked;
      if (option.checked) {
        // Push the item if checked
        this.step?.api.data.feedback.categories?.push(option[this.currentLang]);
      } else if (!option.checked) {
        // Remove the item if unchecked
        const categories = this.step?.api.data.feedback.categories;
        const index = categories?.indexOf(option[this.currentLang]);
        if (index !== -1) {
          categories?.splice(index!, 1); // Using the non-null assertion operator
        }
      }
    }
  }

  // We run this method if user does not select skip option
  submitEmployeeSelection(employee: EmployeeSelectionStepData) {
    if (this.step?.type === StepType.EMPLOYEE_SELECTION) {
      this.step.api.data.condition_value = employee.subdivision_id;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
