import { Injectable } from '@angular/core';
import { CustomVariable } from '../../types/chat-flow.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomVariablesService {
  private customVariablesSubject = new BehaviorSubject<CustomVariable | null>(
    null
  );
  customVariables$ = this.customVariablesSubject.asObservable();

  setCustomVariables(customVariables: CustomVariable): void {
    this.customVariablesSubject.next(customVariables);
  }

  // Method to update multiple variables
  updateVariables(newVariables: CustomVariable): void {
    const currentVariables = this.customVariablesSubject.value || {};
    const updatedVariables = { ...currentVariables, ...newVariables };
    this.customVariablesSubject.next(updatedVariables);
  }

  // Method to get a variable
  getVariable(key: string): string | undefined {
    const currentVariables = this.customVariablesSubject.value;
    return currentVariables ? currentVariables[key] : undefined;
  }
}
