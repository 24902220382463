import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatBotFlowApiService } from '../chat-bot/chat-bot-flow-api.service';
import { ChatFlow } from '../chat-bot/types/chat-flow.interface';
import { UiChatBotSaComponent } from '../chat-bot/components/ui-chat-bot-sa/ui-chat-bot-sa.component';
import { CommonModule } from '@angular/common';
import { IconRegistryService } from 'src/app/shared/services/icon-registry.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomVariablesService } from '../chat-bot/components/ui-chat-bot-sa/custom-variables.service';

@Component({
  selector: 'load-chatbot',
  templateUrl: './load-chatbot.component.html',
  styleUrls: ['./load-chatbot.component.scss'],
  standalone: true,
  imports: [CommonModule, UiChatBotSaComponent, SharedModule],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoadChatbot implements OnInit {
  errorFunnelNotPublished = false;
  errorFunnelNotfound = false;
  errorFunnelStatus: 'funnel_not_found' | 'funnel_not_published' | undefined;
  chatFlow: ChatFlow | null = null;
  funnelLanguage: string | undefined;
  testMode: boolean = false;
  constructor(
    private chatBotFlowApiService: ChatBotFlowApiService,
    private activatedRoute: ActivatedRoute,
    private iconRegistry: IconRegistryService,
    private customVariablesService: CustomVariablesService
  ) {}

  ngOnInit(): void {
    this.iconRegistry.initialize();
    let subdomain: string;
    let funnel_selector: string;
    let lang: string;
    this.activatedRoute.params.subscribe((params) => {
      // Assuming the URL is structured as '/subdomain/funnelSelector'
      subdomain = params['subdomain'];
      funnel_selector = params['funnelSelector'] ?? '';
      lang = params['lang'];
    });
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (lang) {
        this.funnelLanguage = lang;
      }
      this.chatBotFlowApiService
        .fetchFlowConfigFromApi(subdomain, funnel_selector, queryParams, lang)

        .subscribe({
          next: (data) => {
            this.chatFlow = data;
            // Assign query parameters to Variables if keys match, excluding "test"
            Object.keys(queryParams).forEach((key) => {
              if (
                key !== 'test' &&
                this.chatFlow &&
                this.chatFlow.variables.hasOwnProperty(key)
              ) {
                this.chatFlow.variables[key] = queryParams[key];
              }
            });
            // Set the custom variables in customVariables service
            this.customVariablesService.setCustomVariables(
              this.chatFlow.variables
            );
            if (!this.funnelLanguage) {
              this.funnelLanguage = data.language.current_language;
            }
          },
          error: (error) => {
            if (error.error.detail.error == 'funnel_not_published') {
              this.errorFunnelStatus = 'funnel_not_published';
            } else {
              this.errorFunnelStatus = 'funnel_not_found';
            }
          }
        });
    });
  }
}
