import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiConfig, ChatFlow } from '../../types/chat-flow.interface';
import { ApiHttpService } from 'src/app/shared/services/api-http.service';
import { environment } from 'src/environments/environment';
import { Step, StepType } from '../../types/steps.type';

@Injectable({
  providedIn: 'root'
})
export class ChatBotFlowSaService {
  constructor(private apiHttp: ApiHttpService) {}

  // chat flow steps that can be removed later on when we have a backend
  private chatFlowStepsSubject = new BehaviorSubject<Step[]>([]);
  // tracking of user steps && flow config for SA
  private chatFlowSubject = new BehaviorSubject<ChatFlow | null>(null);
  chatFlow$ = this.chatFlowSubject.asObservable();
  // chat flow steps that can be removed later on when we have a backend
  private userStepsSubject = new BehaviorSubject<Step[]>([]);
  userSteps$ = this.userStepsSubject.asObservable();

  // remove step visibility parameter
  addStepToSortedStepsById(id: number): void {
    const currentSteps = this.chatFlowStepsSubject.getValue();
    const currentSortedSteps = this.userStepsSubject.getValue();
    const stepToAdd = currentSteps.find((step) => step.step === id);
    if (stepToAdd) {
      this.userStepsSubject.next([...currentSortedSteps, stepToAdd]);
      // for new component
      const previousVal = this.chatFlowSubject.value;
      if (previousVal) {
        // Create a new object with the updated steps array
        const newVal = {
          ...previousVal, // Spread to copy properties of previousVal
          steps: [...previousVal.steps, stepToAdd] // Create a new array with the new step added
        };
        // Emit the new value
        this.chatFlowSubject.next(newVal);
      }
    }
  }

  setFlowConfig(config: ChatFlow): void {
    this.chatFlowStepsSubject.next(config.steps);
    const flow = { ...config, steps: [...config.steps] };
    this.userStepsSubject.next([config.steps[0]]);
    this.chatFlowSubject.next(flow);
  }

  updateFeedbackApi<T extends StepType>(config: ApiConfig<T>) {
    const urlObj = new URL(config.url);
    // Extract the path and query from the URL
    const pathWithQuery =
      environment.apiServers.apiEndpointFeedback +
      urlObj.pathname +
      urlObj.search;

    if (config.method.toUpperCase() === 'POST') {
      return this.apiHttp.post<any>(pathWithQuery, config.data);
    } else if (config.method === 'PATCH') {
      return this.apiHttp.patch<any>(pathWithQuery, config.data);
    } else {
      // Optionally handle unsupported methods
      throw new Error(`Unsupported method: ${config.method}`);
    }
  }
}
